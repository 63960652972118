
<div class="w-auto h-auto item-status-wrapper">
  <div
    class="flex flex-row px-2 rounded-full"
    :style="{ 'background-color': bgColor }"
  >
    <div class="status-text pr-1 flex flex-row" :style="{ color: btColor }">
      {{ this.status }}
      <slot name="elapse-time"></slot>
    </div>
    <div class="flex flex-col justify-center">
      <v-progress-circular
        v-if="
          status == POD_STATUS.updating ||
          status == POD_STATUS.installing ||
          status == POD_STATUS.restarting ||
          status == POD_STATUS.restart ||
          status == POD_STATUS.resume ||
          status == POD_STATUS.loading
        "
        indeterminate
        :size="18"
        width="2"
        class="ml-2"
        :color="btColor"
      ></v-progress-circular>
      <Tooltip
        v-else-if="status != 'Running' && tooltip != ''"
        :text="tooltip"
        position="right"
        targetSelf
        :contentClass="
          status == POD_STATUS.failed ? 'p-2 text-sm inline-block' : ''
        "
      >
        <v-icon
          size="medium"
          :color="btColor"
          @click="
            () => {
              viewRDEState(true);
            }
          "
        >
          mdi-alert-circle
        </v-icon>
      </Tooltip>
    </div>
  </div>
</div>


<div>
  <label class="label-infra-size"> Infra Size</label>
  <div class="grid grid-cols-3 gap-x-2">
    <div class="flex">
      <Select
        v-model="values[serviceType].resourceSize.cpuUnit"
        :items="cpuUnitOptions"
        label="CPU Unit"
        class="select-v2 flex-1"
        :solo="false"
        @change="(v) => hanldeChange('cpu', v)"
      />
      <Input
        v-model="values[serviceType].resourceSize.cpu"
        label="CPU - Value"
        type="number"
        appendClass="input-text-field-v2 flex-3"
        :rules="[rules.required, validateTotalSize('cpu')]"
        @blur="validate"
      />
    </div>

    <div class="flex">
      <Select
        v-model="values[serviceType].resourceSize.memoryUnit"
        :items="memoryUnitOptions"
        label="Memory Unit"
        appendClass="select-v2 flex-1"
        :solo="false"
        @change="(v) => hanldeChange('memory', v)"
      />
      <Input
        v-model="values[serviceType].resourceSize.memory"
        label="Memory - Value"
        type="number"
        appendClass="input-text-field-v2 flex-3"
        :rules="[rules.required, validateTotalSize('memory')]"
        @blur="validate"
      />
    </div>
  </div>
</div>

<template>
  <section>
    <div class="flex flex-col">
      <div class="w-full ml-1">
        <SearchBar
          :onSearch="onSearch"
          :searchFields="searchFields"
          :isFavoriteOnly="isFavoriteOnly"
          :isFavoriteFirst="isFavoriteFirst"
        >
          <div class="flex flex-row gap-2 mr-2">
            <FButton
              v-if="showFavoriteBtn"
              size="md"
              fill
              color="white"
              class="text-black"
              @click="() => handleClickFavoriteFirst()"
            >
              <SvgIcon
                v-if="isFavoriteFirst"
                iconName="sortFavoriteFilled"
                color="#1A232B"
                size="md"
                :width="16"
                :height="16"
              />
              <SvgIcon
                v-else
                size="md"
                iconName="sortFavorite"
                color="#1A232B"
                :width="16"
                :height="16"
              />
              Favorite First
            </FButton>
            <FButton
              v-if="showFavoriteBtn"
              size="md"
              fill
              color="white"
              class="text-black"
              @click="() => handleClickFavoriteOnly()"
            >
              <v-icon v-if="isFavoriteOnly">mdi-star</v-icon>
              <v-icon v-else>mdi-star-outline</v-icon>
              Favorite Only
            </FButton>
            <FButton
              v-if="showSelectBtn"
              :disabled="!anySelected"
              size="md"
              color="primary"
              @click="() => onClickShutdown()"
            >
              <v-icon>mdi-power</v-icon>
              Shutdown
            </FButton>
            <FButton
              v-if="showAddBtn"
              size="md"
              fill
              depressed
              color="primary"
              @click="() => onClickAdd()"
            >
              <v-icon>mdi-plus</v-icon>
              Add RDE
            </FButton>
          </div>
        </SearchBar>
      </div>
      <div class="p-1">
        <WebIdeTable
          ref="webIdeTable"
          :items="filterItems"
          :showFavoriteBtn="showFavoriteBtn"
          :showSelectBtn="showSelectBtn"
          :onFavorite="onFavorite"
          :viewStateRDE="viewStateRDE"
          :viewResourceRDE="viewResourceRDE"
          @click:remove="handleRemoveItem"
          @selectedws="updateSelectedItems"
          @getting-rde-status-error="onRdeStatusError"
        />
      </div>
    </div>
    <div
      :class="{
        'app-rde-popup-detail': true,
        'app-rde-popup-detail-ative': !!selectedApp,
        'detail-full': isExpanded,
      }"
    >
      <RDELogPopup
        v-if="!!selectedApp"
        :turnOffFunc="() => (selectedApp = null)"
        :selectedApp="selectedApp"
        :setExpand="setExpand"
        :presetTabId="showLogs ? 2 : 0"
      />
    </div>
    <keep-alive>
      <Dialog
        v-model="showResourceRDE"
        persistent
        title="Resource Detail"
        max-width="700"
        @input="() => (selectedResourceRDE = null)"
      >
        <ResourceDetailRDE
          v-if="!!selectedResourceRDE"
          :selectedResourceRDE="selectedResourceRDE"
        />
      </Dialog>
    </keep-alive>
  </section>
</template>

<script>
import SearchBar from "@/components/newWorkspace/common/SearchBar.vue";
import WebIdeTable from "./components/WebIdeTable.vue";
import { SearchMixin } from "@/mixins/SearchMixin";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";
import { RequestMixin } from "@/mixins/RequestMixin";
import { mapState } from "vuex";
import CommonUIControl from "@/helper/CommonUIControl";
import RDELogPopup from "@/pages/newWorkspace/RDELogPopup.vue";
import ResourceDetailRDE from "@/pages/newWorkspace/ResourceDetailRDE.vue";

import {
  updateRdeStatus,
  removeWorkspaceRde,
  setRdeFavorite,
  unsetRdeFavorite,
} from "@/service/apis/workspaceApis";
import { POD_STATUS, ROLE_TYPE } from "@/service/constants";
import { cloneDeep } from "lodash";

export default {
  props: {
    items: { type: Array },
    showFavoriteBtn: { type: Boolean, default: false },
    showSelectBtn: { type: Boolean, default: false },
    showAddBtn: { type: Boolean, default: false },
    workspaceId: { type: String, default: "" },
  },
  components: { SearchBar, WebIdeTable, RDELogPopup, ResourceDetailRDE },
  mixins: [SearchMixin, ProfileAuthMixin, RequestMixin],
  data() {
    return {
      selectedItems: [],
      searchFields: null,
      workspace: {},
      isFavoriteOnly: false,
      isFavoriteFirst: false,
      favoriteItems: [],
      selectedApp: null,
      selectedResourceRDE: null,
      showResourceRDE: false,
      isExpanded: false,
    };
  },
  computed: {
    ...mapState({
      accountInfo: (state) => state.accountInfo,
      workspaceList: (state) => state.workspace.workspaceList,
      roleList: (state) => state.accountInfo.roleList,
      userName: (state) => state.accountInfo.username,
    }),
    isOwner() {
      if (this.isAdmin) return true;
      else {
        const projectId = this.workspace?.projectId;
        return this.roleList.some(
          (item) =>
            item.project.id == projectId && item.role == ROLE_TYPE.OWNER,
        );
      }
    },
    anySelected() {
      return this.selectedItems.some((selectedId) => {
        const item = this.filterItems.find((item) => item.id === selectedId);
        return item && item.status !== POD_STATUS.shutdown;
      });
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(v) {
        this.baseItems = v;
        if (this.baseItems?.length > 0) {
          this.filterItems = this.baseItems;
          this.doDefaultSort("displayName");
        } else {
          this.filterItems = [];
        }
      },
    },
  },
  methods: {
    onSearch(params) {
      this.onFilter(params);
    },
    handleRemoveItem(item) {
      CommonUIControl.confirm({
        title: "원격 개발 환경 삭제",
        message: "정말 삭제하시겠습니까?",
        onConfirm: async () => {
          await this.deleteItem(item.id);
        },
      });
    },
    onClickShutdown() {
      const shutdownIds = cloneDeep(this.selectedItems);
      const listRdeName = this.getListRdeNameByIds(shutdownIds);
      CommonUIControl.confirm({
        title: "원격 개발환경 Shutdown",
        message:
          "원격 개발환경을 Shutdown 하시겠습니까?" + `\n\r•  ${listRdeName}`,
        onConfirm: async () => {
          if (shutdownIds && shutdownIds.length > 0) {
            //this.deleteItem(shutdownIds);
            this.shutdownItems(shutdownIds);
          }
        },
      });
    },
    async shutdownItems(_ids) {
      if (!_ids?.length) return;
      const ids = Array.isArray(_ids) ? _ids : [_ids];

      CommonUIControl.ShowUIProgress();
      Promise.all(
        ids.map((id) => {
          this.$refs.webIdeTable.$refs[`itemRef-${id}`].onShutdown();

          return updateRdeStatus({
            rdeId: id,
            status: POD_STATUS.shutdown,
          });
        }),
      )
        .then(() => {
          CommonUIControl.ShowSuccessToast("Shutdown RDE is success");
        })
        .catch((err) => {
          console.error(err);
          CommonUIControl.ShowErrorToast(err?.message || err, 5000);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    },
    async deleteItem(id) {
      if (!id?.length) return;
      const ids = Array.isArray(id) ? id : [id];

      CommonUIControl.ShowUIProgress();
      Promise.all(
        ids.map((id) => {
          const item = this.filterItems.find((a) => a.id == id);

          return removeWorkspaceRde({
            id: item.id,
            domainName: item.domainInfo.name,
          });
        }),
      )
        .then(() => {
          this.filterItems = this.filterItems.filter(
            (a) => !ids.includes(a.id),
          );
          CommonUIControl.ShowSuccessToast("Delete RDE is success");
        })
        .catch((err) => {
          console.error(err);
          CommonUIControl.ShowErrorToast(err?.message || err, 5000);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    },
    onClickAdd() {
      this.$router.push(`/workspace/${this.workspaceId}/rde/create`);
    },
    updateSelectedItems(value) {
      this.selectedItems = value;
      this.$emit("updateSelectedRde", value);
    },
    handleClickFavoriteOnly() {
      this.isFavoriteOnly = !this.isFavoriteOnly;
      this.isFavoriteFirst = false;
    },
    handleClickFavoriteFirst() {
      this.isFavoriteFirst = !this.isFavoriteFirst;
      this.isFavoriteOnly = false;
    },
    async onFavorite(item) {
      this.updateFavoriteOnViewArr(item.id);
      if (!item.isFavorite) {
        await setRdeFavorite(this.userName, item.id);
      } else {
        await unsetRdeFavorite(this.userName, item.id);
      }
    },
    updateFavoriteOnViewArr(itemId) {
      if (itemId) {
        // Update this.baseItems
        this.baseItems = this.baseItems.map((item) => {
          if (item.id === itemId) {
            return { ...item, isFavorite: !item.isFavorite };
          }
          return item;
        });

        // Update this.filterItems
        this.filterItems = this.filterItems.map((item) => {
          if (item.id === itemId) {
            return { ...item, isFavorite: !item.isFavorite };
          }
          return item;
        });
      }
    },
    viewStateRDE(rde, showLogs = false) {
      this.selectedApp = {
        ...rde,
        projectName: rde.workspace?.projectName,
        profileName: rde.workspace?.profileName,
      };
      this.showLogs = showLogs;
    },
    viewResourceRDE(rde) {
      this.selectedResourceRDE = {
        rdeId: rde?.id,
        namespace: rde.namespace,
        rde: rde.name,
        wsName: rde?.workspace?.name,
      };
      this.showResourceRDE = true;
    },
    onRdeStatusError(params) {
      this.$emit("getting-rde-status-error", params);
    },
    getListRdeNameByIds(ids) {
      return this.filterItems
        .filter(
          (item) =>
            ids.includes(item.id) && item.status !== POD_STATUS.shutdown,
        )
        .map((item) => item.displayName)
        .join("\n\r• ");
    },
    setExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
  beforeMount() {
    this.workspace = this.workspaceList.find(
      (item) => item.id == this.workspaceId,
    );
    this.searchFields = SEARCH_FIELDS;
  },
};

const SEARCH_FIELDS = [{ value: "displayName", label: "Name" }];
</script>

<style lang="scss">
.app-rde-popup-detail {
  position: fixed;
  top: 50px;
  right: -50%;
  width: 0px;
  height: 0px;
  z-index: 5;
  transition: right 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.app-rde-popup-detail-ative {
  width: 50%;
  height: calc(100% - 50px);
  right: 0px;
}
.detail-full {
  width: 100% !important;
}
</style>

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations: {
    setWorkspaceList(state, payload) {
      state.workspaceList = payload;
    },
  },
  actions: {
    setWorkspaceList: ({ commit }, payload) =>
      commit("setWorkspaceList", payload),
  },
};

const initialState = Object.freeze({
  workspaceList: [],
});

<template>
  <component
    :is="svgComponent"
    v-if="svgComponent"
    :color="color"
    :width="width ?? newSize"
    :height="height ?? newSize"
  />
</template>

<script>
import Vue from "vue";

export default {
  /** ===== props ===== */
  props: {
    /** 아이콘 명 */
    iconName: {
      type: String,
      required: true,
    },
    /** 아이콘 사이즈("xs"|"sm"|"md"|"lg"|"xl") */
    color: {
      type: String,
      default: "#222",
    },
    size: {
      type: String,
      default: "md",
    },
    /** custom width */
    width: {
      type: Number,
    },
    /** custom hegiht */
    height: {
      type: Number,
    },
  },
  /** ===== components ===== */
  components: {},
  /** ===== mixins ===== */
  mixins: [],
  /** ===== emits ===== */
  emits: [],
  /** ===== data ===== */
  data: () => ({
    svgComponent: null,
  }),
  /** ===== computed ===== */
  computed: {
    newSize() {
      return typeof this.size === "string"
        ? sizeMap.router(this.size)
        : this.size;
    },
  },
  /** ===== watch ===== */
  watch: {
    iconName: {
      immediate: true,
      async handler(iconName) {
        if (!iconName) {
          return;
        }
        const Component = await icons.router(this.iconName);
        this.svgComponent = Component.default;
      },
    },
  },
  /** ===== methods ===== */
  methods: {},
  /** ===== mounted ===== */
  mounted() {},
  /** ===== beforeDestroy ===== */
  beforeDestroy() {},
};

export const icons = {
  kubernetes: () => import("./resources/KubernetesIcon.vue"),
  kubernetesNode: () => import("./resources/KubernetesNodeIcon.vue"),
  sonarqube: () => import("./resources/SonarqubeIcon.vue"),
  mariadb: () => import("./resources/MariadbOutlineIcon.vue"),
  vscode: () => import("./resources/VscodeIcon.vue"),
  intellij: () => import("./resources/IntelliJIcon.vue"),
  php: () => import("./resources/PhpIcon.vue"),
  ssh: () => import("./resources/SshIcon.vue"),
  go: () => import("./resources/GoIcon.vue"),
  javascript: () => import("./resources/JavascriptIcon.vue"),
  springboot: () => import("./resources/SpringbootIcon.vue"),
  express: () => import("./resources/ExpressIcon.vue"),
  postgresql: () => import("./resources/PostgresqlIcon.vue"),
  typescript: () => import("./resources/TypescriptIcon.vue"),
  terraform: () => import("./resources/TerraformIcon.vue"),
  github: () => import("./resources/GithubIcon.vue"),
  jupyter: () => import("./resources/JupyterIcon.vue"),
  cloud: () => import("./resources/CloudIcon.vue"),
  aws: () => import("./resources/AwsIcon.vue"),
  azure: () => import("./resources/AzureIcon.vue"),
  gcp: () => import("./resources/GcpIcon.vue"),
  project: () => import("./resources/ProjectIcon.vue"),
  profile: () => import("./resources/ProfileIcon.vue"),
  namespace: () => import("./resources/NameSpaceIcon.vue"),
  running: () => import("./resources/RunningIcon.vue"),
  cpu: () => import("./resources/CPUIcon.vue"),
  memory: () => import("./resources/MemoryIcon.vue"),
  disk: () => import("./resources/DiskIcon.vue"),
  eks: () => import("./resources/EksIcon.vue"),
  fargate: () => import("./resources/FargateIcon.vue"),
  sortFavorite: () => import("./resources/SortFavoriteIcon.vue"),
  sortFavoriteFilled: () => import("./resources/SortFavoriteFilledIcon.vue"),
  async router(name) {
    if (!this[name]) {
      throw "not found icon name: " + name;
    }

    const Component = await this[name]();
    return Vue.component(name, Component);
  },
};

const sizeMap = {
  ["xs"]: () => 10,
  ["sm"]: () => 18,
  ["md"]: () => 24,
  ["lg"]: () => 32,
  ["xl"]: () => 40,
  ["2xl"]: () => 62,
  router(size) {
    if (!this[size]) {
      throw new Error("ERROR: sizeMap.router()  invalid `size`: ");
    }
    return this[size]();
  },
};
</script>

<style lang="scss"></style>

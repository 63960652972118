
<div :class="`smart-card ${addionalClass || ''}`">
  <div class="header">
    <slot name="left-header"></slot>
    <div v-if="title" class="title-header">{{ title }}</div>
    <slot name="right-header"></slot>
    <div class="right-icon">
      <v-icon
        v-if="description"
        title="click to show description"
        size="16"
        @click="() => openPopup(true)"
      >
        mdi-help-circle
      </v-icon>
      <v-icon
        v-if="exportFunc"
        title="click to export csv"
        size="18"
        @click="exportFunc"
      >
        mdi-download
      </v-icon>
      <img
        v-if="!hideExpand"
        src="~@/assets/img/expand.svg"
        alt="SVG Icon"
        @click="() => openPopup(false)"
      />
    </div>
  </div>
  <div v-if="isPopupVisible" class="popup" @click="closePopup">
    <div
      :class="{
        'popup-content': true,
        'popup-description': isShowingDescription,
      }"
      @click.stop="() => undefined"
    >
      <div class="header-popup-detail">
        <div v-if="title" class="title-header">{{ title }}</div>
        <v-icon @click="closePopup">mdi-close</v-icon>
      </div>
      <div class="content-popup-detail">
        <slot
          v-if="!isShowingDescription"
          name="content"
          :isPopupVisible="isPopupVisible"
          :setPopupVisible="setPopupVisible"
        ></slot>
        <slot v-if="isShowingDescription">
          <div class="description">
            {{ description }}
          </div>
        </slot>
      </div>
    </div>
  </div>
  <div class="content">
    <slot
      name="content"
      :isPopupVisible="isPopupVisible"
      :setPopupVisible="setPopupVisible"
    ></slot>
  </div>
</div>

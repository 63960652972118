<template>
  <v-autocomplete
    v-model="internalValue"
    :label="label"
    :items="localItems"
    small-chips
    :placeholder="placeholder"
    :class="extClass"
    :clearable="true"
    :deletable-chips="true"
    multiple
    outlined
    dense
    @change="onClearCheckBox"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip :key="index" small class="my-chip">
        <div class="text-center flex flex-col">
          <span class="name">
            {{ item.name }}
            {{ selectedChild?.name ? "|" : "" }}
            {{ selectedChild?.name || "" }}</span
          >
        </div>
      </v-chip>
    </template>

    <template v-slot:item="{ item, index }">
      <v-list-item :key="index" class="image-list" @click.stop>
        <v-list-item-content>
          <v-radio-group
            v-model="selectedValue"
            hide-details
            class="rdo-group w-full"
          >
            <div class="w-full flex flex-row gap-2 parent-item">
              <div>
                <v-radio
                  class="pt-5"
                  :value="item"
                  @change="toggleParent(item)"
                ></v-radio>
              </div>
              <v-list-group v-model="item.expanded" class="w-full" @click.stop>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div class="font-bold">{{ item.name }}</div>
                      <div class="extra-info">
                        Version: {{ item.version }} <br />
                        Description: {{ item.description?.toString() }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(child, childIndex) in item.expended"
                  :key="childIndex"
                  @click.stop
                >
                  <v-list-item-content
                    class="w-full flex flex-row gap-2 child-item"
                  >
                    <div class="w-fit flex-none">
                      <v-radio
                        :value="child"
                        @change="toggleChild(item, child)"
                      ></v-radio>
                    </div>

                    <div class="child-body w-fit flex-none py-2">
                      <div class="font-bold py-1">{{ child.name }}</div>
                      <div class="extra-info">
                        Version: {{ child.version }} <br />
                        Description: {{ child.descriptions?.toString() }}
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
          </v-radio-group>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    extClass: {
      type: String,
      default: "",
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Select",
    },
    xModel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      internalValue: [],
      localItems: cloneDeep(this.items),
      init: false,
      selectedValue: null,
      selectedParent: null,
      selectedChild: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.initData();
      },
    },
    internalValue: {
      immediate: true,
      handler() {
        let out = "";
        if (this.selectedParent) {
          out = this.selectedParent.name;
          if (this.selectedChild) {
            out += `.${this.selectedChild.name}`;
          }
          out += `:${this.selectedParent.version}`;
          this.$emit("input", out);
        }
      },
    },
  },
  methods: {
    toggleParent(item) {
      this.localItems.forEach((parent) => {
        parent.selected = false;
        if (parent.expended) {
          parent.expended.forEach((child) => {
            child.selected = false;
          });
        }
      });
      const parentItem = this.localItems.find(
        (parent) =>
          parent.name === item.name && parent.version === item.version,
      );

      if (parentItem) {
        this.selectedParent = parentItem;
        parentItem.selected = true;
        this.selectedChild = null;
      }
      this.internalValue = this.localItems.filter((item) => item.selected);
    },
    toggleChild(parent, child) {
      this.selectedParent = parent;
      this.localItems.forEach((parent) => {
        parent.selected = false;
        if (parent.expended) {
          parent.expended.forEach((child) => {
            child.selected = false;
          });
        }
      });
      const parentItem = this.localItems.find(
        (p) => p.name === parent.name && p.version === parent.version,
      );

      if (parentItem) {
        parentItem.selected = true;
        const childItem = parentItem.expended.find(
          (c) => c.name === child.name,
        );

        if (childItem) {
          childItem.selected = true;
          this.selectedChild = childItem;
        }
      }
      this.internalValue = this.localItems.filter((item) => item.selected);
    },
    initData() {
      if (!this.init && this.value && this.items.length > 0) {
        this.localItems.forEach((parent) => {
          parent.selected = false;
          if (parent.expended) {
            parent.expended.forEach((child) => {
              child.selected = false;
            });
          }
        });

        // Parse the value
        const [parentPart, version] = this.value.split(":");
        const [parentName, childName] = parentPart.split(".");

        // Find the parent item
        const parentItem = this.localItems.find(
          (parent) => parent.name === parentName && parent.version === version,
        );

        if (parentItem) {
          parentItem.selected = true;
          this.selectedParent = parentItem;
          if (childName) {
            // Find the child item
            const childItem = parentItem.expended.find(
              (child) => child.name === childName,
            );

            if (childItem) {
              childItem.selected = true;
              this.selectedChild = childItem;
              this.selectedValue = childItem;
            }
          } else {
            this.selectedValue = parentItem;
          }
        }
        this.internalValue = this.localItems.filter((item) => item.selected);
        this.init = true;
      }
    },
    onClearCheckBox() {
      if (!this.internalValue || this.internalValue.length == 0) {
        this.localItems.forEach((parent) => {
          parent.selected = false;
          if (parent.expended) {
            parent.expended.forEach((child) => {
              child.selected = false;
            });
          }
        });
        this.selectedValue = null;
        this.selectedParent = null;
        this.selectedChild = null;
        this.$emit("input", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox-parent {
  &.v-input--selection-controls {
    margin-top: 0;
    padding-bottom: 0;
  }
  &.v-messages {
    display: none !important;
  }
}
.checkbox-parent .v-messages,
.checkbox-child .v-messages {
  display: none;
}
.extra-info {
  color: #646569;
  line-height: 18px;
}

.child-item,
.parent-item {
  border-bottom: 1px solid #cdced3;
}
.checkbox-child {
  margin-top: 0;
  display: inline-block !important;
}
.child-body {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
}
.name {
  line-height: 14px;
}
.version {
  font-size: 8px;
  color: #646569;
  line-height: 12px;
}
.my-chip {
  background-color: #321fdb !important;
  color: #fff !important;
}
.rdo-group {
  margin-top: 0 !important;
}
</style>

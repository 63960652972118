export default [
  {
    path: "/remote-development-env/:userName/:wsName/:appName/:namespace/:domainURL/vscode",
    beforeEnter: (to) => {
      const userName = to.params.userName;
      const wsName = to.params.wsName;
      const appName = to.params.appName;
      const domainURL = to.params.domainURL;
      const namespace = to.params.namespace;

      window.open(
        `https://${domainURL}/${userName}-${wsName}-${appName}/${namespace}/vscode`,
        "_blank",
      );
    },
  },
  {
    path: "/remote-development-env/:userName/:wsName/:appName/:namespace/:domainURL/ssh",
    beforeEnter: (to) => {
      const userName = to.params.userName;
      const wsName = to.params.wsName;
      const appName = to.params.appName;
      const domainURL = to.params.domainURL;
      const namespace = to.params.namespace;

      window.open(
        `https://${domainURL}/${userName}-${wsName}-${appName}/${namespace}/cli`,
        "_blank",
      );
    },
    // component: () =>
    //   import("../pages/workspace/RemoteDevelopmentEnvSshPage.vue"),
  },
  {
    path: "/remote-development-env/:userName/:wsName/:appName/:namespace/:domainURL/notebook",
    beforeEnter: (to) => {
      const userName = to.params.userName;
      const wsName = to.params.wsName;
      const appName = to.params.appName;
      const domainURL = to.params.domainURL;
      const namespace = to.params.namespace;

      window.open(
        `https://${domainURL}/${userName}-${wsName}-${appName}/${namespace}/jupyter`,
        "_blank",
      );
    },
    // component: () =>
    //   import("../pages/workspace/RemoteDevelopmentEnvSshPage.vue"),
  },
];


<div class="wrap-cards w-full grid grid-cols-1">
  <div class="child1 col-span-1 grid grid-cols-2 gap-2">
    <div class="col-span-1 flex flex-col justify-center pr-1">
      <div :class="['add-on-info', { 'pl-11': item?.instanceType !== null }]">
        {{ item.name }}
      </div>
      <div class="flex flex-row gap-1">
        <div
          v-if="item?.instanceType != null"
          class="flex flex-col justify-center p-1"
        >
          <SvgIcon
            v-if="item?.instanceType == 'node'"
            iconName="eks"
            color="#006AD4"
            size="lg"
          />
          <SvgIcon v-else iconName="fargate" color="#006AD4" size="lg" />
        </div>

        <span class="workspace-name pt-1"
          >{{ item.displayName || item.name }}
        </span>

        <Tooltip
          v-if="item?.description"
          :text="item?.description"
          position="right"
          targetSelf
          class="pr-1 flex flex-col justify-center"
        >
          <v-icon size="medium"> mdi-information-outline </v-icon>
        </Tooltip>
        <div class="flex flex-col justify-center">
          <ItemStatus
            :status="podStatus"
            :item="item"
            :tooltip="tooltipMsg"
            :viewRDEState="onViewRDEState"
          >
            <template
              v-if="startTime && podStatus == POD_STATUS.running"
              v-slot:elapse-time
            >
              <ElapsedRunTime :startTime="startTime" />
            </template>
          </ItemStatus>
        </div>
      </div>
    </div>
    <div class="col-span-1 flex flex-col justify-center">
      <div class="add-on-info padding mt-1 flex flex-row">
        <div>Workspace:</div>
        <div class="add-on-detail pl-1 flex flex-row">
          <span>{{ item?.workspace?.displayName }}</span>
          <Tooltip
            v-if="item?.workspace?.name"
            :text="`
            System name: <b>${item?.workspace?.name}</b> <br />
            Display name: <b>${item?.workspace?.displayName}</b>
            `"
            position="right"
            targetSelf
            class="pl-1 flex flex-col justify-center"
          >
            <v-icon size="medium"> mdi-information-outline </v-icon>
          </Tooltip>
        </div>
      </div>
      <div class="add-on-info padding">
        Namespace: <span>{{ item?.namespace }}</span>
      </div>
      <div class="add-on-info padding">
        User: <span>{{ item?.userName }}</span>
      </div>
    </div>
  </div>
  <div class="child2 col-span-1 grid grid-cols-2 gap-2">
    <div class="wp-info grid grid-cols-3 gap-3">
      <div class="col-span-1 flex flex-col justify-center">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="cpu" size="sm" color="#87888C" />
          <label>CPU</label>
        </div>
        <div class="resource-box">
          <span
            class="usage-percentage"
            :style="$helper.getUsageStyle(item.currentUsage?.cpu, item?.cpu)"
          ></span>
          <label class="content">
            {{
              $helper.viewCpuData2(item?.currentUsage?.cpu || 0, item?.cpu)
            }}
          </label>
        </div>
      </div>
      <div class="col-span-1 flex flex-col justify-center">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="memory" size="sm" color="#87888C" />
          <label>Memory</label>
        </div>
        <div class="resource-box">
          <span
            class="usage-percentage"
            :style="
              $helper.getUsageStyle(item.currentUsage?.memory, item?.memory)
            "
          ></span>
          <label class="content">
            {{
              $helper.viewMemoryData2(
                item?.currentUsage?.memory || 0,
                item?.memory,
              )
            }}
          </label>
        </div>
      </div>
      <div class="col-span-1 flex flex-col justify-center">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="disk" size="sm" color="#87888C" />
          <label>Disk</label>
        </div>
        <div class="resource-box">
          <span
            class="usage-percentage"
            :style="
              $helper.getUsageStyle(
                this.$helper.byteToGibibyte(item.currentUsage?.disk, 0),
                item?.disk,
              )
            "
          ></span>
          <label class="content">
            {{
              `${$helper.viewDiskData(item.currentUsage?.disk, item?.disk)}`
            }}
          </label>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-col justify-center">
      <div class="actions flex flex-row gap-2 p-2 w-full flex-wrap">
        <div class="icon-resource">
          <v-icon
            :disabled="
              podStatus == POD_STATUS.shutdown ||
              podStatus == POD_STATUS.stop ||
              podStatus == POD_STATUS.failed
            "
            title="view resource detail"
            @click="() => viewResourceRDE(item)"
          >
            mdi-note-text-outline
          </v-icon>
        </div>
        <ButtonStatus
          :available="item.serviceTypes.includes('vscode')"
          :disabled="podStatus !== POD_STATUS.running"
          btType="vscode"
          :status="listServiceStatus.vscodeserver"
          :to="`${buildURL(item)}/vscode`"
          :podStatus="podStatus"
        />
        <ButtonStatus
          :available="item.serviceTypes.includes('webssh')"
          :disabled="podStatus !== POD_STATUS.running"
          btType="ssh"
          :status="listServiceStatus.sshserver"
          :to="`${buildURL(item)}/ssh`"
          :podStatus="podStatus"
        />
        <ButtonStatus
          :available="item.serviceTypes.includes('notebook')"
          :disabled="podStatus !== POD_STATUS.running"
          btType="jupyter"
          :status="listServiceStatus.jupyter"
          :to="`${buildURL(item)}/notebook`"
          :podStatus="podStatus"
        />
        <div class="menu-btn flex flex-col justify-center">
          <div class="text-right flex flex-row">
            <IconButton
              class="border-none"
              size="md"
              iconName="mdi-cached"
              color="#87888C"
              tooltip="Update Status"
              @click="() => reloadRdeStatus(item.id)"
            ></IconButton>
            <v-menu offset-y transition="scale-transition">
              <template v-slot:activator="{ on }">
                <IconButton
                  class="border-none"
                  size="md"
                  iconName="mdi-dots-horizontal"
                  color="#87888C"
                  tooltip="Actions"
                  v-on="on"
                ></IconButton>
              </template>
              <v-list>
                <v-list-item
                  v-for="(menuItem, i) in Menus"
                  :key="i"
                  class="list-rde-menu-item py-1"
                  :disabled="menuItem.disabled"
                  @click="menuItem.action()"
                >
                  <v-list-item-title>
                    <div class="flex flex-row gap-1 items-center">
                      <v-icon
                        v-if="menuItem.icon"
                        :color="
                          menuItem.disabled ? '#00000060' : menuItem.color
                        "
                      >
                        {{ menuItem.icon }}
                      </v-icon>
                      <ImageIcon
                        v-if="menuItem.svgIcon"
                        :iconName="menuItem.svgIcon"
                      />
                      {{ menuItem.title }}
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

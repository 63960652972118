<template>
  <v-data-table
    key="id"
    v-model="selected"
    :expanded.sync="expanded"
    :headers="tableHeaders"
    :items="items"
    item-key="id"
    :show-select="showSelectBtn"
    hide-default-header
    class="ide-table"
  >
    <template v-slot:item="{ item, isExpanded }">
      <tr class="row-ide-table">
        <td>
          <div
            class="ide-row-wrapper flex flex-row gap-2 w-full mb-2"
            :class="{ expanded: isExpanded }"
          >
            <div
              v-if="showFavoriteBtn || showSelectBtn"
              class="flex flex-col justify-center"
            >
              <!-- Favorite -->
              <IconButton
                v-if="showFavoriteBtn"
                outlined
                color="#F9B115"
                :iconName="item.isFavorite ? 'mdi-star' : 'mdi-star-outline'"
                class="favorite-btn"
                size="md"
                @click="onFavorite(item)"
              />

              <!-- Check box -->
              <v-checkbox
                v-if="showSelectBtn"
                v-model="selected"
                :value="item.id"
                class="check-box"
              ></v-checkbox>
            </div>
            <keep-alive>
              <WebIdeTableItem
                :key="`row-component-${item.id}`"
                :ref="`itemRef-${item.id}`"
                :item="item"
                :viewResourceRDE="viewResourceRDE"
                :viewStateRDE="viewStateRDE"
                @getting-rde-status-error="onRdeStatusError"
                @click:remove="onDelete"
              />
            </keep-alive>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { RequestMixin } from "@/mixins/RequestMixin";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";
import WebIdeTableItem from "./WebIdeTableItem.vue";

export default {
  props: {
    items: {
      type: [Array, Object],
    },
    workspaceName: { type: String },
    workspace: { type: Object },
    showSelectBtn: { type: Boolean, default: false },
    showFavoriteBtn: { type: Boolean, default: false },
    onFavorite: { type: Function, default: () => {} },
    viewStateRDE: { type: Function },
    viewResourceRDE: { type: Function },
  },
  components: { WebIdeTableItem },
  mixins: [RequestMixin, ProfileAuthMixin],
  data() {
    return {
      expanded: [],
      selected: [],
      singleExpand: false,
      tableHeaders: [
        {
          text: "Name",
          sortable: false,
          value: "name",
          class: "header-table",
        },
        {
          text: "expand",
          sortable: false,
          value: "",
          class: "header-table",
        },
        { text: "", value: "data-table-expand" },
      ],
      rdePodStatus: "loading",
    };
  },
  watch: {
    selected(value) {
      this.$emit("selectedws", value);
    },
    items() {
      this.selected = [];
    },
  },
  methods: {
    onDelete(item) {
      this.$emit("click:remove", item);
    },
    onRdeStatusError(params) {
      this.$emit("getting-rde-status-error", params);
    },
    reloadRdeStatus(id) {
      if (this.$refs[`itemRef-${id}`]) {
        this.$refs[`itemRef-${id}`].reloadRdeStatus();
      }
    },
  },
};
</script>

<style type="scss">
.ide-table {
  background-color: transparent !important;

  .expanded {
    margin-bottom: 0 !important;
    border-left: 4px solid #321fdb;
  }
  .ide-row-wrapper {
    background-color: #ffffff;
    padding: 10px !important;
    .favorite-btn {
      border: none !important;
    }
    .workspace-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: left;
    }

    .description {
      color: #87888c;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.0025em;
      text-align: left;
    }
    .wp-info {
      color: #87888c;

      label {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
      }
      .content {
        color: #131316;
      }
    }

    .v-input--selection-controls {
      margin-top: 5px !important;
    }
  }

  .row-ide-table {
    padding: 5px !important;
  }

  .row-ide-table td {
    padding: 0 !important;
    border: none !important;
  }

  .row-expand {
    background-color: #f8f9fa;
    padding: 5px;

    button {
      background-color: #ffffff;
      text-transform: unset !important;
    }
    .v-btn--outlined {
      border: thin solid #dadce0 !important;
    }
  }

  .check-box {
    .v-messages {
      display: none !important;
    }
  }

  .list-rde-menu-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .v-application--wrap .v-menu__content .v-list .v-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>

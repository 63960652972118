<template>
  <div
    class="compositions__monaco-editor"
    :class="[
      hideDetails && 'hide-details',
      disabledInteraction && 'disabled-interaction',
    ]"
  >
    <div class="rounded-md overflow-hidden relative">
      <div
        id="container"
        ref="containerRef"
        :style="{ width: newWidth, height: newHeight }"
      ></div>
    </div>

    <VInput
      class="mt-1"
      :value="value"
      :rules="rules"
      @update:error="(v) => (error = v)"
    />
  </div>
</template>

<script>
import "monaco-editor";
import "monaco-yaml";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

export default {
  props: {
    /** 값 */
    value: { type: String, default: "" },
    /** width */
    width: { type: [Number, String], default: undefined },
    /** height */
    height: { type: [Number, String], default: 400 },
    /** 테마 */
    theme: { type: String, default: "vs-dark" },
    /** 언어 */
    language: { type: String, default: "javascript" },
    /** `true`일 경우 미니맵 활성화 */
    miniMap: { type: Boolean, default: true },
    /** `true`일 경우 수정 불가 */
    readOnly: {
      type: Boolean,
      default: false,
    },
    /** `true`일 인터렉션 불가 */
    disabledInteraction: {
      type: Boolean,
    },
    /** true일 경우 메시지 라인 숨김 */
    hideDetails: {
      type: Boolean,
    },
    /** 유효성 검사 */
    rules: {
      type: [Array, Function, Boolean],
      default: () => [],
    },
    /** 그외 옵션 */
    options: { type: Object, default: undefined },
    /** 마운트 후 콜백 */
    editorMounted: { type: Function, default: undefined },
    /** @type {EditorError[] | null | undefined} */
    editorErrors: {
      type: Array,
      default: undefined,
    },
  },
  emits: ["input"],
  data: () => ({
    /** true일 경우 에러 */
    error: null,
    /** 생성된 에디터 객체 */
    editor: null,
    /** 모델 객체 */
    model: null,
    /** 에러등의 데코레이터를 관리하기 위한 인스턴스 */
    editorDecorations: null,
    containerRef: null,
  }),
  computed: {
    /** 옵션 */
    newOptions() {
      return {
        ...this.$props.options,
      };
    },
    /** width */
    newWidth() {
      const isNumber = !isNaN(Number(this.width));

      if (isNumber) {
        return `${this.width}px`;
      }
      return typeof this.width === "string" ? this.width : undefined;
    },
    /** height */
    newHeight() {
      const isNumber = !isNaN(Number(this.height));

      if (isNumber) {
        return `${this.height}px`;
      }
      return typeof this.height === "string" ? this.height : undefined;
    },
  },
  watch: {
    /** language */
    language: {
      immediate: true,
      handler(language) {
        if (!this.editor) {
          return;
        }
        // console.log("> ", language);
        monaco.editor.setModelLanguage(this.model, language);
      },
    },
    /** theme */
    theme: {
      immediate: true,
      handler(theme) {
        if (!this.editor) {
          return;
        }
        this.editor.updateOptions({
          theme,
        });
      },
    },
    /** mini map */
    miniMap: {
      immediate: true,
      handler(miniMap) {
        if (!this.editor) {
          return;
        }
        this.editor.updateOptions({
          minimap: {
            enabled: miniMap,
          },
        });
      },
    },
    /** value */
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        // # 초기 값 변경 시 호출
        if (this.model && v !== this.editor.getValue()) {
          this.editor.setValue(v);
          // 한 화면에 에디터가 여러개고 언어가 풀릴경우 랭귀치 셋
          monaco.editor.setModelLanguage(this.model, this.language);
        }
      },
    },
    readOnly: {
      immediate: true,
      handler(readOnly) {
        if (!this.editor) {
          return;
        }
        this.editor?.updateOptions({ readOnly });
      },
    },
    /** editor errors */
    editorErrors: {
      handler(errors = []) {
        if (!this.editor) {
          return;
        }
        this.setEditorErrors(errors);
      },
    },
  },
  methods: {
    /** 에디터 에러 셋팅
     * @param {EditorError[]} errors 에러 객체
     */
    setEditorErrors(errors = []) {
      if (this.editorDecorations) {
        this.editorDecorations.clear();
      }

      const newErrors = errors.map((a) => ({
        range: new monaco.Range(
          a.startLineNumber,
          a.startColumn ?? 1,
          a.endLineNumber ?? a.startLineNumber,
          a.endColumn ?? 3000,
        ),
        options: {
          className: "squiggly-error",
          hoverMessage: {
            value: a.message,
            isTrusted: false,
          },
        },
      }));

      this.editorDecorations =
        this.editor.createDecorationsCollection(newErrors);
    },

    /** 에디터 에러 초기화 */
    clearEditorErrors() {
      if (this.editorDecorations) {
        this.editorDecorations.clear();
      }
      this.editorDecorations = null;
    },
    /** 초기화 */
    initialize() {
      this.editor = monaco.editor.create(this.$refs.containerRef, {
        value: this.value,
        language: this.language,
        theme: this.theme,
        minimap: {
          enabled: this.miniMap,
        },
        tabSize: 2,
        automaticLayout: true,
        fontFamily: "monospace",
        fontLigatures: true,
        renderIndentGuides: false,
        scrollBeyondLastLine: false,
        readOnly: this.readOnly,
      });

      this.model = this.editor.getModel();

      // # 값 변경 시 이벤트 등록
      this.model.onDidChangeContent(() => {
        const value = this.editor.getValue();
        this.$emit("input", value);
      });

      // # mount event
      if (typeof this.editorMounted === "function") {
        this.editorMounted(this.editor);
      }

      // # editor errors
      if (this.editorErrors) {
        this.setEditorErrors(this.editorErrors);
      }
    },
  },
  mounted() {
    this.clearEditorErrors();
    this.initialize();
  },
  /** unmount */
  destroyed() {
    this.model.dispose();
    // monaco.editor.getModels().forEach((model) => model.dispose());
  },
};

/**
 * line Error 표시
 * @typedef {object} EditorError
 * @property {number} startLineNumber - 라인의 시작 번호
 * @property {number} [endLineNumber] - 라이의 종료 번호. 기본값: startLineNumber
 * @property {number} [startColumn] - 컬럼의 시작 번호. 기본값: 1
 * @property {number} [endColumn] - 컬럼의 종료 번호. 기본값: 3000
 * @property {string} message - 에러 메시지
 */
</script>

<style lang="scss">
.compositions__monaco-editor {
  &.hide-details > .v-input {
    display: none;
  }
  /** 인터렉션 비활성화 */
  &.disabled-interaction {
    .view-overlays,
    .cursors-layer {
      display: none;
    }
    .view-lines {
      cursor: auto !important;
    }
  }
  & > .v-input > .v-input__control > .v-input__slot {
    display: none;
  }
}
</style>

import RdeContainerPage from "../pages/RdeContainer/RdeContainerPage.vue";

export default [
  {
    path: "rde-containers",
    component: RdeContainerPage,
    meta: {
      title: "My RDE",
      isMenuItem: true,
      breadCrumb: [{ text: "My RDE" }],
    },
  },
];

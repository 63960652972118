<template>
  <div>
    <label class="label-infra-size"> Infra Size</label>
    <div class="grid grid-cols-3 gap-x-2">
      <div class="flex">
        <Select
          v-model="values[serviceType].resourceSize.cpuUnit"
          :items="cpuUnitOptions"
          label="CPU Unit"
          class="select-v2 flex-1"
          :solo="false"
          @change="(v) => hanldeChange('cpu', v)"
        />
        <Input
          v-model="values[serviceType].resourceSize.cpu"
          label="CPU - Value"
          type="number"
          appendClass="input-text-field-v2 flex-3"
          :rules="[rules.required, validateTotalSize('cpu')]"
          @blur="validate"
        />
      </div>

      <div class="flex">
        <Select
          v-model="values[serviceType].resourceSize.memoryUnit"
          :items="memoryUnitOptions"
          label="Memory Unit"
          appendClass="select-v2 flex-1"
          :solo="false"
          @change="(v) => hanldeChange('memory', v)"
        />
        <Input
          v-model="values[serviceType].resourceSize.memory"
          label="Memory - Value"
          type="number"
          appendClass="input-text-field-v2 flex-3"
          :rules="[rules.required, validateTotalSize('memory')]"
          @blur="validate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/atoms/Input/Input.vue";
import Select from "@/components/atoms/Select/Select.vue";
import { RulesMixin } from "@/mixins/RulesMixin";
import { debounce } from "lodash";

export default {
  props: {
    serviceType: { type: String },
    values: { type: Object },
    requirementInfraResource: { type: Array },
    validate: { type: Function },
  },
  components: { Select, Input },
  mixins: [RulesMixin],
  data() {
    return {
      cpuUnitOptions: [
        { text: "Core", value: "core" },
        { text: "Millicore", value: "millicore" },
      ],
      memoryUnitOptions: [
        { text: "MiB", value: "MiB" },
        { text: "GiB", value: "GiB" },
      ],
    };
  },
  methods: {
    parseValue(value, name, serviceType) {
      if (
        name === "cpu" &&
        this.values[serviceType || this.serviceType].resourceSize.cpuUnit ===
          "core"
      ) {
        // parse to millicore to compare
        return Number(value) * 1000;
      }

      if (
        name === "memory" &&
        this.values[serviceType || this.serviceType].resourceSize.memoryUnit ===
          "GiB"
      ) {
        // parse to Mib to compare
        return Number(value) * 1024;
      }

      return Number(value);
    },
    hanldeChange: debounce(function (name, value) {
      if (name === "cpu") {
        if (value === "core") {
          this.values[this.serviceType].resourceSize.cpu =
            Number(this.values[this.serviceType].resourceSize.cpu) / 1000;
        } else {
          this.values[this.serviceType].resourceSize.cpu =
            Number(this.values[this.serviceType].resourceSize.cpu) * 1000;
        }
      }

      if (name === "memory") {
        if (value === "GiB") {
          this.values[this.serviceType].resourceSize.memory =
            Number(this.values[this.serviceType].resourceSize.memory) / 1024;
        } else {
          this.values[this.serviceType].resourceSize.memory =
            Number(this.values[this.serviceType].resourceSize.memory) * 1024;
        }
      }
    }, 200),
    validateTotalSize(name) {
      return (valueParam) => {
        let value = this.parseValue(valueParam, name);

        if (this.requirementInfraResource) {
          const selectedMinimum = this.requirementInfraResource?.find(
            (r) => r?.serviceType === this.serviceType,
          )?.[name]?.value;
          if (selectedMinimum && value < selectedMinimum) {
            return `Minimum ${name} of ${
              this.serviceType
            } is ${selectedMinimum} ${name === "cpu" ? "millicore" : "MiB"}`;
          }
        }

        const totalSize = Number(this.values.resourceSize[name]);

        const currentTotal = this.values.serviceTypes.reduce(
          (total, serviceType) => {
            return serviceType !== this.serviceType
              ? total +
                  this.parseValue(
                    Number(this.values[serviceType].resourceSize[name]),
                    name,
                    serviceType,
                  )
              : total + Number(value);
          },
          0,
        );

        if (currentTotal > totalSize)
          return `Total ${name} of the service type exceeds ${totalSize} ${
            name === "cpu" ? "millicore" : "MiB"
          } `;

        return true;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.label-infra-size {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.flex-3 {
  flex: 3;
}
</style>

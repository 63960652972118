<template>
  <div :class="`smart-card ${addionalClass || ''}`">
    <div class="header">
      <slot name="left-header"></slot>
      <div v-if="title" class="title-header">{{ title }}</div>
      <slot name="right-header"></slot>
      <div class="right-icon">
        <v-icon
          v-if="description"
          title="click to show description"
          size="16"
          @click="() => openPopup(true)"
        >
          mdi-help-circle
        </v-icon>
        <v-icon
          v-if="exportFunc"
          title="click to export csv"
          size="18"
          @click="exportFunc"
        >
          mdi-download
        </v-icon>
        <img
          v-if="!hideExpand"
          src="~@/assets/img/expand.svg"
          alt="SVG Icon"
          @click="() => openPopup(false)"
        />
      </div>
    </div>
    <div v-if="isPopupVisible" class="popup" @click="closePopup">
      <div
        :class="{
          'popup-content': true,
          'popup-description': isShowingDescription,
        }"
        @click.stop="() => undefined"
      >
        <div class="header-popup-detail">
          <div v-if="title" class="title-header">{{ title }}</div>
          <v-icon @click="closePopup">mdi-close</v-icon>
        </div>
        <div class="content-popup-detail">
          <slot
            v-if="!isShowingDescription"
            name="content"
            :isPopupVisible="isPopupVisible"
            :setPopupVisible="setPopupVisible"
          ></slot>
          <slot v-if="isShowingDescription">
            <div class="description">
              {{ description }}
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="content">
      <slot
        name="content"
        :isPopupVisible="isPopupVisible"
        :setPopupVisible="setPopupVisible"
      ></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    hideExpand: {
      type: Boolean,
      default: false,
    },
    addionalClass: {
      type: String,
    },
    description: {
      type: String,
    },
    exportFunc: {
      type: Function,
    },
  },
  data() {
    return {
      isPopupVisible: false,
      isShowingDescription: false,
    };
  },
  methods: {
    openPopup(isShowingDescription = false) {
      this.isPopupVisible = true;
      this.isShowingDescription = isShowingDescription;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.isShowingDescription = false;
    },
    setPopupVisible(isPopupVisible) {
      this.isPopupVisible = isPopupVisible;
    },
    checkEscKey(event) {
      // click esc to close popup
      if (event.key === "Escape" || event.keyCode === 27) {
        this.closePopup();
      }
    },
  },
  mounted() {
    window.addEventListener("keyup", this.checkEscKey);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.checkEscKey);
  },
};
</script>

<style scoped>
.smart-card {
  padding: 8px;
  padding-right: 4px;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.title-header {
  background: rgba(51, 153, 255, 0.1);
  border: 1px solid #3399ff;
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  color: #2a2d45;
}

.header-popup-detail {
  display: flex;
  justify-content: flex-end;
}

.header {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  .right-icon {
    display: flex;
    gap: 5px;
    align-items: self-start;
    justify-content: flex-end;
    img {
      width: 24px;
      height: 24px;
    }
    flex-wrap: wrap;
  }
}
.header-title {
  justify-content: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.content {
  margin-top: 5px;
  overflow: auto;
  flex-grow: 1;
}

.popup-content {
  background: white;
  padding: 10px 20px;
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  .title-header {
    width: fit-content;
    margin: auto;
    font-size: 16px;
  }

  .content-popup-detail {
    flex-grow: 1;
    min-height: calc(100% - 30px);
  }
}

.popup-description {
  width: 40%;
  height: fit-content;
  min-width: 500px;
  min-height: 300px;
  font-size: 14px;
  .description {
    margin-top: 10px;
  }
}
</style>

<template>
  <PageTemplate
    :useCard="false"
    padding="p-0"
    :breadcrumbs="[
      {
        text: 'My RDE',
      },
    ]"
  >
    <div class="">
      <TopRdeStatusBar
        :workspaceId="workspaceId"
        :wsInfo="wsInfo"
        :usageInfo="usageInfo"
      />
    </div>
    <div class="m-1 mt-4">
      <WebIdeList
        :items="rdeItems"
        :showFavoriteBtn="true"
        :viewStateRDE="viewStateRDE"
        @getting-rde-status-error="onRdeStatusError"
      />
    </div>
    <div
      :class="{
        'app-rde-popup-detail': true,
        'app-rde-popup-detail-ative': !!selectedApp,
      }"
    >
      <RDELogPopup
        v-if="!!selectedApp"
        :turnOffFunc="() => (selectedApp = null)"
        :selectedApp="selectedApp"
      />
    </div>
  </PageTemplate>
</template>

<script>
import PageTemplate from "@/components/common/PageTemplate/PageTemplate.vue";
import CommonUIControl from "@/helper/CommonUIControl";
import WebIdeList from "@/components/newWorkspace/components/WebIdeList/WebIdeList.vue";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";
import { RequestMixin } from "@/mixins/RequestMixin";
import { mapState } from "vuex";
import { getWorkspaceByUser } from "@/service/apis/workspaceApis";
import RDELogPopup from "@/pages/newWorkspace/RDELogPopup.vue";
import { getRdeListMetrics } from "@/service/apis/workspaceApis";
import TopRdeStatusBar from "@/components/RdeContainer/TopRdeStatusBar.vue";
import { WorkspaceMixin } from "@/mixins/WorkspaceMixin";

const FV_KEY = "_fv_web_ide";

export default {
  props: {
    workspaceId: String,
  },
  components: {
    PageTemplate,
    WebIdeList,
    RDELogPopup,
    TopRdeStatusBar,
  },
  mixins: [ProfileAuthMixin, RequestMixin, WorkspaceMixin],
  data: () => ({
    rdeItems: [],
    selectedApp: null,
    wsInfo: {
      limitCpu: 0,
      limitMemory: 0,
      limitDisk: 0,
    },
    usageInfo: {
      cpu: 0,
      memory: 0,
      disk: 0,
    },
  }),
  computed: {
    ...mapState({
      userName: (state) => state.accountInfo.username,
      accountInfo: (state) => state.accountInfo,
    }),
    fvKey() {
      return FV_KEY;
    },
  },
  methods: {
    async getWorkspace() {
      CommonUIControl.ShowUIProgress();
      getWorkspaceByUser(this.userName)
        .then(async (res) => {
          const workspaces = res?.data?.data;

          workspaces.map((item) => {
            let newRdes = item.ideConfigInfoList;

            newRdes.forEach((rde) => {
              rde.serviceTypes = rde.serviceTypes.split(",");
              rde.workspace = {
                name: item.name,
                isOwner: this.verifyOwner(item.projectId),
                displayName: item?.displayName,
                id: item?.id,
              };
            });

            this.rdeItems = this.rdeItems.concat(newRdes);

            // Get workspace info
            this.wsInfo.limitCpu += parseInt(item.limitCpu) || 0;
            this.wsInfo.limitMemory += parseInt(item.limitMemory) || 0;
            this.wsInfo.limitDisk += parseInt(item.limitDisk) || 0;
          });

          CommonUIControl.HideUIProgress();

          // Get Rde metrics
          await this.getRdeMetrics();
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(err, 5000);
          CommonUIControl.HideUIProgress();
        });
    },
    onSearch(params) {
      this.onFilter(params);
    },
    viewStateRDE(rde) {
      this.selectedApp = {
        ...rde,
        projectName: this.wsInfo?.projectName,
        profileName: this.wsInfo?.profileName,
      };
    },
    async getRdeMetrics() {
      const res = await getRdeListMetrics();

      if (res) {
        const metricList = res?.data?.result;

        this.rdeItems = this.rdeItems.map((item) => {
          const metricData = metricList.find((metric) => metric.id === item.id);

          const currentUsage = {
            cpu: this.$helper.roundNumber(
              this.getLatestData(metricData.cpu || 0) || 0,
            ),
            memory: this.$helper.byteToMebibyte(
              this.getLatestData(metricData.memory) || 0,
            ),
            disk: this.$helper.byteToGibibyte(metricData.volumeUsed) || 0,
          };

          this.usageInfo.cpu += currentUsage.cpu;
          this.usageInfo.memory += currentUsage.memory;
          this.usageInfo.disk += currentUsage.disk;

          this.usageInfo = {
            cpu: this.$helper.roundNumber(this.usageInfo.cpu),
            memory: this.$helper.roundNumber(this.usageInfo.memory),
            disk: this.$helper.roundNumber(this.usageInfo.disk),
          };

          return {
            ...item,
            currentUsage,
          };
        });
      }
    },
    onRdeStatusError(params) {
      let listRde = [];
      getWorkspaceByUser(this.userName)
        .then((res) => {
          const workspaces = res?.data?.data;

          workspaces.map((item) => {
            let newRdes = item.ideConfigInfoList;
            listRde = listRde.concat(newRdes);
          });
          // Find error rde in workspace list
          const exist = listRde.find((item) => item.id == params.id);
          if (!exist) {
            this.rdeItems = this.rdeItems.filter(
              (item) => item.id != params.id,
            );
          }
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    },
  },
  async beforeMount() {
    this.getWorkspace();
  },
};
</script>

<style lang="scss">
.tab-btns-wrapper {
  padding: 4px;
  border-radius: 10px;
  .tab-btns {
    background: transparent !important;
    .v-btn--has-bg {
      background-color: transparent;
    }
    .v-btn {
      text-transform: none !important;
      min-width: 80px !important;
    }
  }
  .duration {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: #87888c;
  }
}
.app-rde-popup-detail {
  position: fixed;
  top: 50px;
  right: -50%;
  width: 0px;
  height: 0px;
  z-index: 5;
  transition: right 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.app-rde-popup-detail-ative {
  width: 50%;
  height: calc(100% - 50px);
  right: 0px;
}
</style>

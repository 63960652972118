import { cloneDeep, isNull } from "lodash";
import CommonUIControl from "@/helper/CommonUIControl";

export const SearchMixin = {
  data() {
    return {
      baseItems: [],
      filterItems: [],
    };
  },
  methods: {
    onFilter(params) {
      const searchConditions = params?.search;
      const sortConditions = params?.sort;
      if (params) {
        this.search(searchConditions, sortConditions);
      } else {
        this.filterItems = this.baseItems;
      }
    },
    search(searchParams, sortParams) {
      if (this.baseItems && this.baseItems.length > 0) {
        let searchItems = cloneDeep(this.baseItems);
        if (searchParams) {
          for (var key in searchParams) {
            if (!isNull(searchParams[key])) {
              searchItems = searchItems.filter(function (item) {
                if (item[key] === undefined || item[key] === null) return false;
                const dataType = typeof item[key];
                switch (dataType) {
                  case "string": {
                    return item[key]
                      .toLowerCase()
                      .includes(searchParams[key].toLowerCase());
                  }
                  case "boolean":
                  case "number": {
                    return item[key] == searchParams[key];
                  }
                  default:
                    return false;
                }
              });
            }
          }
        }

        if (sortParams && Array.isArray(sortParams)) {
          this.filterItems = cloneDeep(searchItems);
          this.filterItems.sort((a, b) => {
            for (let sortCondition of sortParams) {
              const { field, order } = sortCondition;
              const typeData = typeof a[field];
              if (typeData === "string") {
                if (order) {
                  return a[field].localeCompare(b[field]);
                } else {
                  return b[field].localeCompare(a[field]);
                }
              } else {
                if (a[field] < b[field]) return order === true ? -1 : 1;
                if (a[field] > b[field]) return order === true ? 1 : -1;
              }
            }
            return 0;
          });
        } else {
          this.filterItems = searchItems;
        }
      }
    },
    sort(items, field, asc = true) {
      let sortItems = [];

      if (items[0] && !(field in items[0])) {
        return items;
      }

      if (items.length > 1 && !isNull(field) && !isNull(items[0][field])) {
        const typeData = typeof items[0][field];
        if (typeData === "string") {
          if (asc) {
            sortItems = items.sort((a, b) => a[field].localeCompare(b[field]));
          } else {
            sortItems = items.sort((a, b) => b[field].localeCompare(a[field]));
          }
        } else {
          if (asc) {
            sortItems = items.sort((a, b) => (a[field] > b[field] ? 1 : -1));
          } else {
            sortItems = items.sort((a, b) => (a[field] < b[field] ? 1 : -1));
          }
        }
        return sortItems;
      } else {
        return items;
      }
    },
    confirmDelete(title, message, confirmAction) {
      CommonUIControl.confirm({
        title: title,
        message: message,
        onConfirm: async () => {
          await confirmAction();
        },
      });
    },
    doDefaultSort(fieldName) {
      if (fieldName != "") {
        this.onFilter({
          sort: [
            {
              field: fieldName,
              order: true,
            },
          ],
        });
      }
    },
  },
};

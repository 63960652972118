import { genAgoTime } from "./helper";

export const DATETIME_FORMAT = "HH:mm DD/MM/YYYY";
export const DATETIME_FORMAT_SECOND = "HH:mm:ss DD/MM/YYYY";

export const PR_TYPE = {
  PR: "PR",
  NON_PR: "NON_PR",
};

export const mapRequestCICDFields = {
  reportPeriod: "reportPeriod",
  cluster: "clusterIds",
  project: "projectIds",
  profile: "profileIds",
  application: "appIds",
  group: "groups",
  "dateRange.startDate": "fromDate",
  "dateRange.endDate": "toDate",
  startDate: "fromDate",
  endDate: "toDate",
  isNonPR: "type",
};

export const COLORS = {
  SUCCEEDED: "#2EB85C",
  FAILED: "#E55353",
  PRIMARY: "#3399FF",
  WARNING: "#F9B115",
  GRAY: "#B6B7BC",
};

export const PIPELINE_STATUS = {
  SUCCEEDED: {
    label: "SUCCEEDED",
    borderColor: COLORS.SUCCEEDED,
    backgroundColor: COLORS.SUCCEEDED,
  },
  FAILED: {
    label: "FAILED",
    borderColor: COLORS.FAILED,
    backgroundColor: COLORS.FAILED,
  },
  RUNNING: {
    label: "RUNNING",
    borderColor: COLORS.PRIMARY,
    backgroundColor: COLORS.PRIMARY,
  },
};

export const STATUS_ICON_MAP = {
  SUCCEEDED: {
    color: "var(--green)",
    opacity: "rgb(0, 204, 0, 0.3)",
    icon: "mdi-chevron-down-circle",
    text: "SUCCEEDED",
  },
  FAILED: {
    color: "var(--danger)",
    opacity: "rgb(204, 51, 0, 0.3)",
    icon: "mdi-close-circle",
    text: "FAILED",
  },
  Running: {
    color: "var(--green)",
    opacity: "rgb(0, 204, 0, 0.3)",
    icon: "mdi-chevron-down-circle",
    text: "Running",
  },
  Error: {
    color: "var(--danger)",
    opacity: "rgb(204, 51, 0, 0.3)",
    icon: "mdi-close-circle",
    text: "Error",
  },
  Undeployed: {
    color: COLORS.GRAY,
    text: "Undeployed",
  },
  Degraded: {
    color: COLORS.WARNING,
    text: "Degraded",
  },
  Completed: {
    color: COLORS.SUCCEEDED,
    text: "Completed",
  },
};

export const STATUS_HONEY_COMB = {
  Running: {
    color: COLORS.SUCCEEDED,
    text: "Running",
  },
  Error: {
    color: COLORS.FAILED,
    text: "Error",
  },
  ELSE: {
    color: COLORS.WARNING,
    text: "Undeployed",
  },
};

// data response is second
// now we need a convert unit as 60 to display minutes
// we can change to 1 to show in seconds
export const CONVERT_TIME_UNIT = 60;

// this a typical value for a duration pipeline (minutes)
export const BASE_LINE_HORIZONTAL = 10;

export const TOP_RECENT_PIPELINES_HEADER = [
  {
    text: "App Name",
    sortable: true,
    value: "appName",
    class: "header-table",
  },
  {
    text: "Build Duration",
    sortable: true,
    value: "duration",
    class: "header-table",
    minWidth: "100px",
  },
  {
    text: "Time",
    sortable: true,
    value: "timestamp",
    class: "header-table",
  },
  {
    text: "",
    sortable: true,
    value: "status",
    class: "header-table",
  },
];

export const APPLICATION_HEADER = [
  {
    text: "App Name",
    sortable: true,
    value: "appName",
    class: "header-table th-app-name",
  },
  {
    text: "Status",
    sortable: true,
    value: "status",
    class: "header-table th-status",
  },
  {
    text: "Profile",
    sortable: true,
    value: "profileName",
    class: "header-table th-profile",
  },
  {
    text: "Branch/Commit",
    sortable: false,
    value: "branchCommit",
    class: "header-table th-branch-commit",
  },
  {
    text: "Image Name",
    sortable: true,
    value: "imageName",
    class: "header-table th-image-name",
  },
  {
    text: "Image Version",
    sortable: true,
    value: "imageTag",
    class: "header-table th-image-version",
  },
  {
    text: "Start",
    sortable: true,
    value: "start",
    class: "header-table th-start",
  },
  {
    text: "Duration",
    sortable: true,
    value: "duration",
    class: "header-table th-duration",
  },
];

export const NUMBER_OF_BUILDS_OPTIONS = [5, 10, 20, 30, 50];

export const FORMAT_TIME = {
  day: "DD/MM/YYYY",
  hour: "HH:mm DD/MM/YYYY",
  month: "MM/YYYY",
};

export const RANGES_DEFAULT_DATETIME_PICKER = () => {
  // Get the current date and time
  const currentDate = new Date();

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  return {
    "Last 2 weeks": [genAgoTime(20160), currentDate],
    "Last 3 hours": [genAgoTime(180), currentDate],
    "Last 24 hours": [genAgoTime(1440), currentDate],
    "Last 2 days": [genAgoTime(2880), currentDate],
    Today: [today, currentDate],
    Yesterday: [yesterday, today],
    "Last 3 months": [genAgoTime(129600), currentDate],
    "Last 6 months": [genAgoTime(259200), currentDate],
    "Last 12 months": [genAgoTime(518400), currentDate],
  };
};

export const TIME_CHART_UNIT = {
  HOUR: {
    unit: "hour",
    displayFormats: {
      hour: "HH:mm",
    },
  },
  DAY: {
    unit: "day",
    displayFormats: {
      day: "DD/MM",
    },
  },
  WEEK: {
    unit: "week",
    displayFormats: {
      week: "[W]WW/YYYY",
    },
  },
  MONTH: {
    unit: "month",
    displayFormats: {
      month: "MM/YYYY",
    },
  },
  YEAR: {
    unit: "year",
    displayFormats: {
      hour: "YYYY",
    },
  },
};

export const GEN_TOP_KUBERNETES_HEADER = (isCPU, isPopupVisible) => [
  {
    text: "App Name",
    sortable: false,
    value: "name",
    class: "header-table",
    minWidth: "120px",
  },
  ...(isPopupVisible
    ? [
        {
          text: "Pod Name",
          sortable: false,
          value: "podName",
          class: "header-table",
          minWidth: "120px",
        },
      ]
    : []),
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "header-table",
    minWidth: "95px",
  },
  ...(isPopupVisible
    ? [
        {
          text: "Node",
          sortable: false,
          value: "node",
          class: "header-table",
          minWidth: "90px",
        },
      ]
    : [
        {
          text: "Replicas",
          sortable: false,
          value: "replicas",
          class: "header-table",
          minWidth: "70px",
        },
      ]),
  {
    text: "Age",
    sortable: false,
    value: "age",
    class: "header-table",
    minWidth: "90px",
  },
  {
    text: isCPU ? "CPU (millicores)" : "Memory",
    sortable: false,
    value: "value",
    class: "header-table",
    minWidth: "90px",
  },
];

export const POD_TABLE_HEADER = [
  {
    text: "Name",
    sortable: false,
    value: "name",
    class: "header-table",
    minWidth: "110px",
  },
  {
    text: "Node",
    sortable: false,
    value: "node",
    class: "header-table",
    minWidth: "80px",
  },
  {
    text: "Namespace",
    sortable: false,
    value: "namespace",
    class: "header-table",
    minWidth: "80px",
  },
  {
    text: "Ready",
    sortable: false,
    value: "ready",
    class: "header-table",
    minWidth: "60px",
  },
  {
    text: "CPU (millicores)",
    sortable: false,
    value: "cpu",
    class: "header-table",
    minWidth: "60px",
  },
  {
    text: "Memory (MiB)",
    sortable: false,
    value: "memory",
    class: "header-table",
    minWidth: "60px",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "header-table",
    minWidth: "80px",
  },
];

export const APP_STATUS = {
  Running: "Running",
  Error: "Error",
  Degraded: "Degraded",
  Completed: "Completed",
  Undeployed: "Undeployed",
};

export const APP_TYPE = {
  Deployment: "Deployment",
  StatefulSet: "StatefulSet",
};

export const KUBERNETES_SUMMARY_LABEL = {
  Total: "#3399FF",
  [APP_STATUS.Running]: "#321FDB",
  [APP_STATUS.Completed]: "#2EB85C",
  [APP_STATUS.Error]: "#E55353",
  [APP_TYPE.Deployment]: "#646569",
  [APP_TYPE.StatefulSet]: "#646569",
};

export const APP_STATUS_COLORS = {
  Running: COLORS.PRIMARY,
  Error: COLORS.FAILED,
  Degraded: COLORS.WARNING,
  Completed: COLORS.SUCCEEDED,
  Undeployed: COLORS.GRAY,
};

export const DEFAULT_VALUE_RANGE_PICKER = 14; // days
export const MAX_DURATION_START_END = 30; // days;

export const MILI_CORE_TO_NANO = 1000000;
export const MIB_TO_KI = 1024;
export const CORE_TO_MILI_CORE = 1000;

export const TIME_REFRESH = 60000; // MILISECONDS

export const REPORT_PERIOD = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
};

export const BENCH_MARKS_HEADER = [
  {
    text: "Metric",
    sortable: false,
    value: "metric",
    class: "header-table",
    width: "20%",
  },
  {
    text: "Low",
    sortable: false,
    value: "low",
    class: "header-table",
    width: "20%",
  },
  {
    text: "Medium",
    sortable: false,
    value: "medium",
    class: "header-table",
    width: "20%",
  },
  {
    text: "High",
    sortable: false,
    value: "high",
    class: "header-table",
    width: "20%",
  },
  {
    text: "Elite",
    sortable: false,
    value: "elite",
    class: "header-table",
    width: "20%",
  },
];

export const PIPELINE_TYPE = { CI: "CI", CD: "CD" };

export const DORA_BENCH_MARKS_LEVEL_CONFIG = {
  DEPLOYMENT_FREQUENCY: {
    metric: "Deployment Frequency",
    elite: "Multiple deploys per day",
    high: "Between once per day and one per week",
    medium: "Between once per week and once per month",
    low: "Fewer than once per month",
    valueRange: [
      { type: "elite", range: [1, null] },
      { type: "high", range: [1 / 7, 1] },
      { type: "medium", range: [1 / 30, 1 / 7] },
      { type: "low", range: [null, 1 / 30] },
    ],
  },
  LEAD_TIME_CHANGE: {
    metric: "Lead Time for Changes",
    elite: "Less than one day",
    high: "Between one day and one week",
    medium: "Between one week and one month",
    low: "More than one month",
    valueRange: [
      { type: "elite", range: [null, 24] },
      { type: "high", range: [24, 24 * 7] },
      { type: "medium", range: [24 * 7, 24 * 30] },
      { type: "low", range: [24 * 30, null] },
    ],
  },
  CHANGE_FAILURE_RATE: {
    metric: "Change Failure Rate",
    elite: "0 - 5%",
    high: "5 - 10%",
    medium: "10 - 15%",
    low: "15 - 100%",
    valueRange: [
      { type: "elite", range: [0, 5] },
      { type: "high", range: [5, 10] },
      { type: "medium", range: [10, 15] },
      { type: "low", range: [15, 100] },
    ],
  },
};

export const CHART_DESCRIPTION = {
  TOTAL_COUNT:
    "This chart displays the total number of CI/CD pipelines executed during a specified period, broken down by project, profile, application, and group. It helps to intuitively understand the overall activity of pipelines activated during that period.",
  AVG_BUILD_TIME:
    "This chart provides the average build time for builds executed during a specified period, categorized by project, profile, application, and group. This data is essential for estimating expected build times in specific projects or environments, and for analyzing efficiency in development and operational settings to devise strategies for improving build performance.",
  TIME_DEVIATION:
    "This chart displays the time taken for each pipeline to complete over a specified period, represented as a line graph. The x-axis indicates the time period, while the y-axis shows the duration taken by each pipeline. This graph can be used to track the flow of time in pipelines and analyze the causes of delays in specific pipelines. A dotted line on the y-axis represents the standard time level, helping to assess the duration of pipelines on specific days.",
  RUNNING_STATE:
    "This chart shows the number of pipelines executed on a daily basis. It allows users to observe how the number of pipeline executions varies during weekdays, weekends, and on specific days. This information can be used to analyze workload fluctuations and optimize operational planning.",
  NUMBER_OF_BUILDS:
    "This chart presents the total number of pipeline executions and the proportion of those that were successful or failed, in the form of a donut chart based on the queried conditions. The success rate is shown as a percentage of the total executions that were successful, and the failure rate is indicated by the percentage of failures. This provides an intuitive understanding of the efficiency of the pipelines.",
  DAILY_BUILD_FREQUENCY:
    "This chart illustrates the number of successful and failed pipeline builds per day over a specified period. The x-axis represents the dates, while the y-axis shows the number of successful builds for each date. Failed builds are indicated on the chart with a separate color. This distinct representation allows users to quickly discern the ratio of successful to failed builds on specific dates and track the variation in success and failure rates over time. Hovering over each data point provides more detailed information.",
  AVERAGE_BUILD_DURATION:
    "This chart illustrates the average execution time of pipeline builds per day over a specified period, displayed as a line graph. This includes the average times of both successful and failed builds. If failed builds are quickly terminated to prepare for the next execution, the graph will show a shorter average duration. Conversely, if failed builds are delayed for extended periods, the overall average time depicted in the graph will increase. This data aids in assessing the overall efficiency of the build process.",
  LONGEST_10_BUILDS:
    "This table presents the top 10 longest builds. It details the duration of each build and the date when it was requested, including the application name, build duration, and request time. This data serves as a fundamental resource for tracking issues that may be causing delays in the build process for specific applications.",
  HONEYCOMB_VIEW: `This Honeycomb View provides the most recent pipeline status of applications in a honeycomb layout. The statuses are shown as 'Running', 'Error', and 'Undeployed'. By double-clicking on an item with your mouse, you can navigate to the detailed pipeline status screen for that application.`,
  AGGREGATE_INFORMATION_OF_MULTIPLE_APPLICATIONS:
    "This scatter plot graph represents the distribution of all pipelines requested over time, with each dot signifying an individual build. This allows for an intuitive understanding of the quantity of builds and their respective durations. The graph includes a dotted line on the y-axis to represent the standard time benchmark, which can be used to evaluate if the builds are completing within the expected timeframe. If the same application shows variable build durations, this can indicate the impact of the deployment environment on performance, serving as a metric for improvement.",
  APPLICATION_LIST: `This list provides the latest pipeline execution information for applications. The table includes the application name, status, profile, branch/commit details, image name and version, start time, and duration of execution. This data can help identify whether an application is operating smoothly or experiencing issues. Selecting an 'App Name' will navigate to a screen offering more detailed information about that particular application.`,
  TIME_DURATION_TRENDS_OVER_RECENT_N_BUILDS_DEPLOYMENTS: `This chart shows the duration of the most recent build/deployment operations. Users can select from options of 5, 10, 20, 30, or 50 to view the time trends of recent pipelines. The chart differentiates between successful and failed builds with distinct colors. The x-axis indicates the start time of each build/deployment, and the y-axis represents the duration taken. This information is useful for evaluating the recent performance of the build/deployment processes.`,
  LONGEST_10_BUILDS_APPDETAIL:
    "This chart table provides detailed information about the 10 longest-running builds requested during the specified period. It includes the status of success or failure, branch/commit details, the name and version of the image used for deployment, the start time, and the duration of each build. Users can click on the status icon to view a detailed execution history for a build, and the commit details link to the corresponding Git service. Accessing detailed information and Git service may require proper system permissions.",
};

export const FIELD_EXPORT_CONFIG = {
  TIME_DEVIATION: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
  RUNNING_STATE: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Quantity",
    timestamp: "Excuted Time",
  },
  DAILY_BUILD_FREQUENCY: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Quantity",
    timestamp: "Excuted Time",
  },
  AVERAGE_BUILD_DURATION: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Avg duration (minutes)",
    timestamp: "Excuted Time",
  },
  LONGEST_10_BUILDS: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    duration: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
  AGGREGATE_INFORMATION_OF_MULTIPLE_APPLICATIONS: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
  APPLICATION_LIST: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    imageName: "Image Name",
    imageTag: "Image Tag",
    branch: "Branch",
    status: "Status",
    duration: "Duration (minutes)",
    pipeLineMessage: "Message",
    url: "Commit",
  },
  HONEYCOMB_VIEW: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    viewReplica: "Replica",
    status: "Status",
    type: "Type",
    namespace: "Namespace",
  },
  TIME_DURATION_TRENDS_OVER_RECENT_N_BUILDS_DEPLOYMENTS: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
};

export const COLOR_COLLECTOR = [
  "#0095AC", // Common Cyan
  "#FD7E14", // Orange
  "#E83E8C", // Pink
  "#FFD700", // Gold
  "#8A2BE2", // Blue Violet
  "#FF4500", // Orange Red
  "#2E8B57", // Sea Green
  "#00CED1", // Dark Turquoise
  "#FF69B4", // Hot Pink
  "#CD5C5C", // Indian Red
  "#4B0082", // Indigo
  "#7FFF00", // Chartreuse
  "#FF1493", // Deep Pink
  "#1E90FF", // Dodger Blue
  "#00FA9A", // Medium Spring Green
  "#FF6347", // Tomato
  "#4682B4", // Steel Blue
  "#DA70D6", // Orchid
  "#32CD32", // Lime Green
  "#00FF7F", // Spring Green
  "#FF00FF", // Magenta
  "#00FFFF", // Cyan
  "#7B68EE", // Medium Slate Blue
  "#DC143C", // Crimson
  "#FF8C00", // Dark Orange
  "#8B0000", // Dark Red
  "#00BFFF", // Deep Sky Blue
  "#ADFF2F", // Green Yellow
  "#DDA0DD", // Plum
  "#F08080", // Light Coral
  "#FF7F50", // Coral
  "#B0E0E6", // Powder Blue
  "#DAA520", // Goldenrod
  "#778899", // Light Slate Gray
  "#BA55D3", // Medium Orchid
  "#FFB6C1", // Light Pink
  "#FA8072", // Salmon
  "#E9967A", // Dark Salmon
  "#9400D3", // Dark Violet
  "#FFDEAD", // Navajo White
  "#98FB98", // Pale Green
  "#AFEEEE", // Pale Turquoise
  "#DB7093", // Pale Violet Red
  "#FFDAB9", // Peach Puff
  "#CD853F", // Peru
  "#FFC0CB", // Pink
  "#DDA0DD", // Plum
  "#B0C4DE", // Light Steel Blue
];

export const DEFAULT_CUSTOMIZED_METRIC_DORA_CYCLETIME = {
  "DORA Metrics": {
    "Overall DORA Metric": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Deployment Frequency": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Lead time for change": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Change Failure Rate": {
      show: true,
      fixed: false,
      span: 1,
    },
  },
  "Cycle Time Metrics": {
    "CI Number of Execution": {
      show: true,
      fixed: false,
      span: 1,
    },
    "CD Number of Execution": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Cycle Time Overview": {
      show: true,
      fixed: false,
      span: 2,
    },
    "Build Time": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Deployment Time": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Cycle Time Breakdown": {
      show: true,
      fixed: false,
      span: 2,
    },
  },
  "Top App and PR": {
    "Top most actively developed applications recently": {
      show: true,
      fixed: false,
      span: 2,
    },
    "Top recent PRs with significant code changes": {
      show: true,
      fixed: false,
      span: 2,
    },
  },
};

export const CUSTOMIZED_METRIC_CODE_QUALITY = {
  "Code Quality": {
    "Commit Frequency": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Coding Time": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Average Review Duration": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Historical Work On Branches": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Average PRs Size": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Ongoing PRs": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Contributors by PRs": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Most Deployed Applications": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Most Committed Applications": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Most Builed Applications": {
      show: true,
      fixed: false,
      span: 1,
    },
  },
};

export const CODE_QUALITITY_TABLE = {
  ON_GOING_PR_TABLE_HEADER: [
    {
      text: "PR Title",
      sortable: false,
      value: "prTitle",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "PR Author",
      sortable: false,
      value: "prAuthor",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Created Date",
      sortable: false,
      value: "prCreatedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Number of Reviewers",
      sortable: false,
      value: "numberOfReviewers",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Number of comments",
      sortable: false,
      value: "numberOfComments",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "Status",
      sortable: false,
      value: "status",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  HISTORICAL_BRANCH_TABLE_HEADER: [
    {
      text: "Branch Name",
      sortable: false,
      value: "branchName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Create Date",
      sortable: false,
      value: "createdDate",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Commit",
      sortable: false,
      value: "lastCommittedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Number of Commits",
      sortable: false,
      value: "noOfCommits",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Number of Contributors",
      sortable: false,
      value: "noOfContributors",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "Merge Status",
      sortable: false,
      value: "status",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  CONTRIBUTORS_TABLE_HEADER: [
    {
      text: "Contributor Name",
      sortable: false,
      value: "userName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of PRs",
      sortable: false,
      value: "noOfPRs",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Lines of Code Added",
      sortable: false,
      value: "locAdded",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Line of Code Deleted",
      sortable: false,
      value: "locDeleted",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Number of Files Changed",
      sortable: false,
      value: "noFiles",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  MOST_DEPLOY_TABLE_HEADER: [
    {
      text: "Application Name",
      sortable: false,
      value: "appName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of Deployments",
      sortable: false,
      value: "noOfDeployments",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Deployment Date",
      sortable: false,
      value: "lastDeployedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Version",
      sortable: false,
      value: "version",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Deployment Environment",
      sortable: false,
      value: "profileName",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  MOST_COMMITED_TABLE_HEADER: [
    {
      text: "Application Name",
      sortable: false,
      value: "applicationName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of Commits",
      sortable: false,
      value: "noOfCommits",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Commit Date",
      sortable: false,
      value: "lastCommittedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Number of Contributors",
      sortable: false,
      value: "noOfContributor",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Line of Code Changed",
      sortable: false,
      value: "noOfChanged",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  MOST_BUILD_TABLE_HEADER: [
    {
      text: "Application Name",
      sortable: false,
      value: "appName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of Builds",
      sortable: false,
      value: "noOfBuild",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Build Date",
      sortable: false,
      value: "lastBuiltDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Build Status",
      sortable: false,
      value: "status",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "avg Build Time",
      sortable: false,
      value: "builtDuration",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
};
